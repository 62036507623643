const en = {
  tips: {
    tips1: 'Metamask not detected',
    tips2: 'Failed to check balance',
    tips3: 'The value of the swap token must be greater than ',
    tips4: 'The value of the swap token must be less than ',
    tips5: 'Failed to create order',
    tips6: 'Successful transaction',
    tips7: 'Transaction failed',
    tips8: 'Insufficient gas fee, pls check the asset balance',
    tips9: ' Insufficient balance',
    tips10: 'Broadcast transaction is successful, please wait for confirmation',
    tips11: 'Confirm to Connect Wallet',
    tips12: 'Connect Wallet',
    tips13: 'Copy successful',
    tips14: 'Approve is successful, please wait for confirmation',
    tips15: 'Broadcast transaction failed',
    tips16: 'The available staking balance is insufficient',
    tips17: 'The available balance of the selected asset is insufficient',
    tips18: "The staking amount can't be 0",
    tips19: 'Failed to confirm wallet connection',
    tips20: ' Insufficient balance',
    tips21: 'Please select the platform address',
    tips22: 'Failed to generate L2 address',
    tips23: 'Receiving assets does not support cross-chain exchange',
    tips24: 'Transaction confirmation in progress',
    tips25: 'No trading pairs available',
    tips26: 'No support for transfer of non-primary assets',
    tips27: 'The received income will be locked for 90 days',
    tips28: 'You can unlock your Nabox by staking NABOX-BUSD LP and you can check the unlocking speed of different amounts of LP through the calculator',
    tips29: 'Audit by',
    tips30: 'Harvest',
    tips31: 'Please enter a number greater than 0 and less than 50',
    tips32: 'Transaction',
    tips33: 'Cross-chain',
    tips34: 'Order timed out, please re-create order',
    tips35: 'High Price Volatility',
    tips36: 'Swap amount is lower than Gas',
    tips37: 'The current channel has not been opened yet',
    tips38: 'The function is being upgraded, please be patient...',
    tips39: 'Insufficient Liquidity',
    tips40: 'Cross-chain',
    tips41: 'This is a single-currency market-making protocol , you can use assets from different networks to provide liquidity and stake the LP in the Farm to earn reward',
    tips42: 'Assets Size',
    tips43: 'My',
    tips44: 'Support',
    tips45: 'Deposit/Withdraw',
    tips46: ' address ',
    tips47: ' will receive',
    tips48: 'Select receiving network',
    tips49: 'Search by token name',
    tips50: 'Switch to supported networks',
    tips51: 'Network timed out, please try again later...',
    tips52: 'The current chain is not currently supported',
    tips53: 'The network connection is unstable, please resend the transaction',
    tips54: 'Please select the receiving network',
    tips55: 'No provider was found',
    tips56: 'Please log in to the plugin first',
    tips57: 'Please fill in the ',
    tips58: ' network receiving address',
    tips59: 'Receive address format error',
    tips60: 'The address to receive the asset',
    tips61: 'Please enter the receiving asset address',
    tips62: 'Tips',
    tips63: 'The current plugin does not support the selected chain, do you want to switch plugins?',
    tips64: 'Confirm',
    tips65: 'Total fee income',
    tips66: 'Insufficient asset balance, pls check the asset balance',
    tips67: 'The minimum stake amount is ',
    tips68: 'Anyone can create a contract token with any name coins, including creating fake versions of existing tokens and it is called the token of a certain project.',
    tips69: 'If you buy fake or wrong tokens, you may Unable to sell it.',
    tips70: 'Import Assets',
    tips71: 'Import',
    tips72: 'View in browser',
    tips73: 'I understand the risks',
    tips74: 'Added by user',
    tips75: 'Switch to ',
    tips76: ' for trade',
    tips77: 'The maximum slippage cannot exceed 50%.',
    tips78: 'Slippage protection exceeds 10%, do you confirm the redemption?',
    tips79: 'Slippage protection is triggered, please try again or adjust the slippage.'
  },
  swap: {
    swap1: 'From',
    swap2: 'To',
    swap3: 'Max',
    swap4: 'Available',
    swap5: 'Rate',
    swap6: 'Fee',
    swap7: 'Channel',
    swap8: 'Next',
    swap9: 'Order History',
    swap10: 'Exchange',
    swap11: 'Order Information',
    swap12: 'Assets',
    swap13: 'Network',
    swap14: 'Exchange',
    swap15: 'No order information temporarily',
    swap16: 'Gnerate multi-chain addresses',
    swap17: 'Expected',
    swap18: 'Channel',
    swap19: 'Best',
    swap20: 'Lowest Received',
    swap21: 'Swap cross-chain transaction not confirmed',
    swap22: 'Swap cross-chain transaction confirmed',
    swap23: 'Swap cross-chain transaction, swap transaction has been broadcast',
    swap24: 'Swap cross-chain transaction, cross-chain transaction has been broadcasted',
    swap25: 'Confirmed',
    swap26: 'Swap cross-chain transaction failed',
    swap27: 'Insufficient trading liquidity',
    swap28: 'ID',
    swap29: 'You need at least ',
    swap30: ' as a handling fee',
    swap31: '【The fee is 0.02% of the transaction amount, it will be charged as 1U if less than 1U】',
    swap32: 'Expected',
    swap33: 'Impact',
    swap34: 'Cross-chain Fee',
    swap35: 'Price calculating',
    swap36: 'Slippage',
    swap37: 'Order Creation',
    swap38: 'User has initiated a transaction',
    swap39: 'Processing swap',
    swap40: 'Swap successful',
    swap41: 'Swap failed',
    swap42: 'OrderId',
    swap43: 'Provider Fee',
    swap44: 'Transaction successful',
    swap45: 'Transaction failed',
    swap46: 'Processing transaction',
    swap47: 'The maximum exchange amount is ',
    swap48: 'Fee = Swap Fee + Token Transfer Fee',
    swap49: 'Swap Fee',
    swap50: 'Uncompleted Transaction',
    swap51: 'Transaction Not Completed',
    swap52: 'Enter transaction hash',
    swap53: 'Enter Hash',
    swap54: 'Paste',
    swap55: 'Click to query hash',
    swap56: 'Enter transaction hash',
    swap57: 'slippage',
    swap58: 'Transactions',
    swap59: 'Maximum Purchase Amount is ',
    swap60: 'Minimum Purchase Amount is ',
    swap61: 'Please enter a valid address.',
    swap62: 'Reference Price',
    swap63: 'Please enter the payment amount',
    swap64: 'Select a currency',
    swap65: 'Currency',
    swap66: 'Token',
    swap67: 'Select a token'
  },
  transfer: {
    transfer1: 'From',
    transfer2: 'To',
    transfer3: 'Assets',
    transfer4: 'Amount',
    transfer5: 'Available',
    transfer6: 'Max',
    transfer7: 'Fees',
    transfer8: 'Approve',
    transfer9: 'Next',
    transfer10: 'Use other asset'
  },
  pool: {
    join1: 'Receive',
    join2: 'Total',
    join3: 'My',
    join4: 'Confirm',
    join5: 'You can get the corresponding amount of ',
    join6: 'Deposit',
    join7: 'Withdraw',
    join8: "Exceeded the pool's maximum withdrawal amount",
    join9: 'Exceeded the maximum withdrawal amount',
    join10: 'The available withdrawal balance is insufficient',
    join11: 'Cross-chain Fee',
    join12: ' after adding liquidity'
  },
  vaults: {
    vaults1: 'Total ',
    vaults2: 'Live',
    vaults3: 'End',
    vaults4: 'Staked ',
    vaults5: 'Available',
    vaults6: 'Max',
    vaults7: 'Cancel',
    vaults8: 'Confirm',
    vaults9: 'Enter the staking amount',
    vaults10: 'The received proceeds will be locked in ',
    vaults11: ' day',
    vaults12: 'Locked ',
    vaults13: 'Unlocked unclaimed ',
    vaults14: 'Complete',
    vaults15: 'Stake',
    vaults16: 'Unstake',
    over1: 'APR',
    over2: 'Earned',
    over3: 'Claim',
    over4: ' Amount',
    over5: 'Get ',
    over6: 'Approve',
    over7: 'Unlock'
  },
  modal: {
    modal1: 'Select',
    modal2: 'Search by name or contract address',
    modal3: 'No data',
    modal4: 'Search Currency',
    modal5: 'Search Token'
  },
  popUp: {
    popUp1: 'Transfer in ',
    popUp2: 'Transfer Out ',
    popUp3: 'Transfer',
    popUp4: 'Exchange',
    popUp5: 'Transaction',
    popUp6: 'Withdraw',
    popUp7: 'Stake LP',
    popUp8: 'L2 transfer'
  },
  confirmOrder: {
    confirmOrder1: 'Confirm'
  },
  navBar: {
    navBar1: 'Order Confirmation',
    navBar2: 'Liquidity',
    navBar3: 'Vaults',
    navBar4: 'Transaction Details',
    navBar5: 'Swap',
    navBar6: 'Airdrop',
    navBar7: 'L1 Farm',
    navBar8: 'L2 Farm'
  },
  order: {
    order1: 'Order Information',
    order2: 'Exchange confirmed',
    order3: 'Waiting for exchange',
    order4: 'Exchange failed',
    order5: 'Order number',
    order6: 'Fees',
    order7: 'Date',
    order8: 'TXID'
  },
  airdrop: {
    airdrop1: 'Current Total Amount',
    airdrop2: 'My ',
    airdrop3: 'Staked',
    airdrop4: 'Stake To Unlock',
    airdrop5: 'Unlocked',
    airdrop6: 'Claim',
    airdrop7: 'Stake',
    airdrop8: 'Unstake',
    airdrop9: 'Remaining Locked',
    airdrop10: 'Get LP',
    airdrop11: 'Unlock Speed',
    airdrop12: 'Unlock Time',
    airdrop13: 'Hours',
    airdrop14: 'Calculator',
    airdrop15: 'Block',
    airdrop16: 'My Unlocked '
  },
  buy: {
    buy1: 'Service Provider',
    buy2: 'Price',
    buy3: 'Quantity',
    buy4: 'Estimated Quantity',
    buy5: 'Network',
    buy6: 'Select a Currency',
    buy7: 'Please provide a valid address',
    buy8: 'Reference Price',
    buy9: 'Buy',
    buy10: 'Buy',
    buy11: 'Channel',
    buy12: 'Buy Details',
    buy13: 'Pay',
    buy14: 'Get',
    buy15: 'Disclaimer',
    buy16: 'I have read and agreed',
    buy17: 'You are about to leave the SwapBox website and redirect to a third-party platform. Users shall visit and use this third-party website at your own risk. We are not responsible for any contents, products, services or other inquiries on the external website. Please contact the customer service of the third-party website for help if you encounter any problems when using the services provided by this website.'
  }
};

export default en;
