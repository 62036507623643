const cn = {
  tips: {
    tips1: '未检测到Metamask',
    tips2: '查询余额失败',
    tips3: '兑换的代币价值需大于',
    tips4: '兑换的代币价值需小于',
    tips5: '创建订单失败',
    tips6: '交易成功',
    tips7: '交易失败',
    tips8: '手续费不足，请检查资产余额',
    tips9: '余额不足',
    tips10: '广播交易成功，请等待区块确认',
    tips11: '确认连接钱包',
    tips12: '连接钱包',
    tips13: '复制成功',
    tips14: '授权成功,请等待区块确认',
    tips15: '广播交易失败',
    tips16: '可用质押余额不足',
    tips17: '当前选择资产可用余额不足',
    tips18: '质押数量不能为0',
    tips19: '同步钱包失败',
    tips20: '余额不足',
    tips21: '请选择平台地址',
    tips22: '生成L2地址失败',
    tips23: '接收资产暂不支持跨链兑换',
    tips24: '交易确认中',
    tips25: '暂无可用交易对',
    tips26: '暂未支持非主资产转账',
    tips27: '领取的收益将在90天内处于锁定状态',
    tips28: '你可以通过质押NABOX-BUSD LP来解锁Nabox，可通过计算器查看不同数量LP的解锁速度',
    tips29: '审计机构',
    tips30: '收获',
    tips31: '请输入大于0小于50的数字',
    tips32: '交易',
    tips33: '跨链交易',
    tips34: '订单超时，请重新创建订单',
    tips35: '价格波动高',
    tips36: '交易金额低于目标链Gas',
    tips37: '当前通道暂未开通',
    tips38: '功能升级中，请耐心等待...',
    tips39: '流动性不足',
    tips40: '跨链兑换',
    tips41: '这是一个单币做市协议，你可使用不同网络的资产提供流动性并将获得的凭证在Farm中质押以获得收益',
    tips42: '资金池规模',
    tips43: '我的',
    tips44: '支持网络',
    tips45: '加入/撤出',
    tips46: '账户',
    tips47: '将获得',
    tips48: '选择接收网络',
    tips49: '输入资产名称搜索',
    tips50: '请切换到对应链',
    tips51: '网络超时，请稍后再试',
    tips52: '暂不支持当前链',
    tips53: '网络连接不稳定，请重发交易',
    tips54: '请选择接收网络',
    tips55: '未找到提供者',
    tips56: '请先登录插件',
    tips57: '请填入',
    tips58: '网络接收地址',
    tips59: '接收地址格式错误',
    tips60: '接收资产地址',
    tips61: '请输入接收资产地址',
    tips62: '提示',
    tips63: '当前插件不支持所选链，是否前往切换插件？',
    tips64: '前往切换',
    tips65: '手续费总收入',
    tips66: '资产余额不足，请检查资产余额',
    tips67: '最低质押数量为',
    tips68: '任何人都可以能以任何名称创建合约代 币，包括创建现有代币的虚假版本和声 称是某个项目的代币。',
    tips69: '如果您购买虚假或错误的代币，您可能 无法将其卖出。',
    tips70: '导入资产',
    tips71: '导入',
    tips72: '浏览器中查看',
    tips73: '我理解其中的风险',
    tips74: '由用户添加',
    tips75: '切换到',
    tips76: '进行交易',
    tips77: '滑点设置最高不能超过50%',
    tips78: '滑点保护超过10%，您是否确认兑换？',
    tips79: '触发兑换滑点保护，请重试或调整滑点。'
  },
  swap: {
    swap1: '从',
    swap2: '到',
    swap3: '最大',
    swap4: '可用',
    swap5: '汇率',
    swap6: '手续费',
    swap7: '通道',
    swap8: '下一步',
    swap9: '订单历史',
    swap10: '兑换',
    swap11: '订单信息',
    swap12: '资产',
    swap13: '网络',
    swap14: '兑换',
    swap15: '暂无订单信息',
    swap16: '派发多链地址',
    swap17: '预计接收',
    swap18: '交易通道',
    swap19: '当前最优',
    swap20: '最低收到',
    swap21: 'swap跨链交易未确认',
    swap22: 'swap跨链交易已确认',
    swap23: 'swap跨链交易，swap交易已广播',
    swap24: 'swap跨链交易，链跨交易已广播',
    swap25: '已确认',
    swap26: 'swap跨链交易失败',
    swap27: '交易流动性不足',
    swap28: '地址',
    swap29: '你至少需要',
    swap30: '用作手续费',
    swap31: '【费用为交易金额的0.02%，若不满1U则按照1U收取】',
    swap32: '预计收到',
    swap33: '价格影响',
    swap34: '跨链手续费',
    swap35: '价格计算中',
    swap36: '滑点',
    swap37: '订单创建',
    swap38: '用户已发起交易',
    swap39: '处理兑换中',
    swap40: '兑换成功',
    swap41: '兑换失败',
    swap42: '订单号',
    swap43: '流动性提供者费用',
    swap44: '交易成功',
    swap45: '交易失败',
    swap46: '处理交易中',
    swap47: '最大兑换数量为：',
    swap48: '手续费 = 兑换手续费 + 提币手续费',
    swap49: '兑换手续费',
    swap50: '有未完成交易',
    swap51: '交易未完成',
    swap52: '未解析到交易hash，点击补录',
    swap53: '补录hash',
    swap54: '粘贴',
    swap55: '点击查询hash',
    swap56: '请输入交易hash',
    swap57: '滑点',
    swap58: '交易记录',
    swap59: '最大购买金额为',
    swap60: '最低购买金额为',
    swap61: '请填写有效地址',
    swap62: '参考价格',
    swap63: '请输入支付金额',
    swap64: '选择一种货币',
    swap65: '货币',
    swap66: '代币',
    swap67: '选择一种代币'
  },
  transfer: {
    transfer1: '从',
    transfer2: '到',
    transfer3: '资产',
    transfer4: '数量',
    transfer5: '可用',
    transfer6: '最大',
    transfer7: '手续费',
    transfer8: '授权',
    transfer9: '下一步',
    transfer10: '使用其他资产'
  },
  pool: {
    join1: '将获得',
    join2: '全网',
    join3: '我的',
    join4: '确认',
    join5: '加入流动性后，可获得对应数量的',
    join6: '加入',
    join7: '撤出',
    join8: '超过Pool最大撤出金额',
    join9: '超过最大撤出金额',
    join10: '可用撤出余额不足',
    join11: '跨链费用',
    join12: ''
  },
  vaults: {
    vaults1: '总',
    vaults2: '进行中',
    vaults3: '已结束',
    vaults4: '质押',
    vaults5: '可用',
    vaults6: '最大',
    vaults7: '取消',
    vaults8: '确认',
    vaults9: '输入质押数量',
    vaults10: '领取的收益将在',
    vaults11: '天内处于锁定状态',
    vaults12: '锁定中的',
    vaults13: '已解锁未领取的',
    vaults14: '完成解锁',
    vaults15: '质押',
    vaults16: '退出质押',
    over1: '年化收益',
    over2: '已赚取',
    over3: '领取',
    over4: '数量',
    over5: '获取',
    over6: '资产授权',
    over7: '解锁'
  },
  modal: {
    modal1: '选择',
    modal2: '输入名字或地址搜索',
    modal3: '暂无数据',
    modal4: '搜索货币',
    modal5: '搜索代币'
  },
  popUp: {
    popUp1: '转入',
    popUp2: '转出',
    popUp3: '转账',
    popUp4: '兑换',
    popUp5: '交易',
    popUp6: '退出质押',
    popUp7: '质押Lp',
    popUp8: 'L2转账'
  },
  confirmOrder: {
    confirmOrder1: '确认'
  },
  navBar: {
    navBar1: '订单确认',
    navBar2: '流动性',
    navBar3: '矿池',
    navBar4: '交易详情',
    navBar5: '兑换',
    navBar6: '空投',
    navBar7: 'L1农场',
    navBar8: 'L2农场'
  },
  order: {
    order1: '订单信息',
    order2: '兑换已确认',
    order3: '等待兑换',
    order4: '兑换失败',
    order5: '订单号',
    order6: '手续费',
    order7: '时间',
    order8: '交易hash'
  },
  airdrop: {
    airdrop1: '当前总量',
    airdrop2: '我的',
    airdrop3: '已质押',
    airdrop4: '质押解锁',
    airdrop5: '已解锁',
    airdrop6: '领取',
    airdrop7: '质押',
    airdrop8: '退出质押',
    airdrop9: '剩余已解锁',
    airdrop10: '获取 LP',
    airdrop11: '解锁速度',
    airdrop12: '解锁时间',
    airdrop13: '小时',
    airdrop14: '计算器',
    airdrop15: '区块',
    airdrop16: '我已解锁的'
  },
  buy: {
    buy1: '服务商',
    buy2: '价格',
    buy3: '数量',
    buy4: '预估数量',
    buy5: '网络',
    buy6: '选择一种货币',
    buy7: '请填写有效地址',
    buy8: '参考价格',
    buy9: '买',
    buy10: '购买',
    buy11: '渠道',
    buy12: '购买详情',
    buy13: '支付',
    buy14: '获得',
    buy15: '免责声明',
    buy16: '我已知晓并同意',
    buy17: '您即将离开 SwapBox网站并跳转到第三方平台。用户应自行承担访问和使用该第三方网站的风险。我们不对外部网站上的任何内容、产品、服务或其他查询负责。如果您在使用此网站提供的服务时遇到任何问题，请联系第三方网站客服寻求帮助。'
  }
};

export default cn;
